// useFetchCategorias.ts
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Categoria {
  id: string;
  name: string;
}

const fetchCategorias = async (): Promise<Categoria[]> => {
  const token = sessionStorage.getItem('ml_access_token');
  const response = await axios.post(
    `${import.meta.env.VITE_APP_API_URL}/meli/proxy-request`,
    {
      url: 'https://api.mercadolibre.com/sites/MLB/categories',
    },
    {
      headers: {
        AuthorizationMeli: `Bearer ${token}`, // Add your token here
      },
    }
  );
  // Filtra a categoria "Carros, Motos e Outros" com o ID "MLB1743"
  return response.data.filter(
    (categoria: Categoria) =>
      categoria.id !== 'MLB1743' &&
      categoria.id !== 'MLB1540' &&
      categoria.id !== 'MLB1459' &&
      categoria.id !== 'MLB218519'
  );
};

const useFetchCategorias = () => {
  return useQuery<Categoria[], Error>({
    queryKey: ['categoriasTop'],
    queryFn: fetchCategorias,
    staleTime: 0, // Sempre considera os dados obsoletos
    gcTime: 0, // Remove o cache imediatamente após desmontar
  });
};

export default useFetchCategorias;
